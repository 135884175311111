import * as React from "react"
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react"
import BlogLayout from "../../../components/word/BlogLayout"
import { graphql } from "gatsby"
import WordTag from "../../../components/word/WordTag"
import { ChevronRightIcon } from "@chakra-ui/icons"

export default function TagIndexPage({ data }: any): JSX.Element {
  const { allMdx }: any = data
  const { nodes }: any = allMdx
  let tags: string[] = []
  for (let node of nodes) {
    if (node.frontmatter.tags) {
      for (let tag of node.frontmatter.tags) {
        tags.push(tag)
      }
    }
  }
  tags = Array.from(new Set(tags)).sort()
  return (
    <BlogLayout pageTitle={"tags"}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        pt={6}
        pl={4}
        pb={1}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/word">word</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/word/tag">tag</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt={8} ml={4}>
        <Flex flexDirection={"column"} gap={2} mt={4} ml={2}>
          {tags &&
            tags.map((tag: string, idx: number) => {
              return (
                <Box>
                  <WordTag text={tag} kind={"tag"} key={idx} />
                </Box>
              )
            })}
        </Flex>
      </Box>
    </BlogLayout>
  )
}

export const query = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`
